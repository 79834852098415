export const repsOptions = [
    "status",
    "binding obligations",
    "non conflict with other obligations",
    "[no reduction of capital]",
    "power and authority",
    "validity and admissibility in evidence",
    "governing law and enforcement",
    "insolvency",
    "no deduction of tax",
    "no filing or stamp taxes",
    "no default",
    "no misleading information",
    "financial statements",
    "[accounting reference date;]",
    "no proceedings pending or threatened",
    "no breach of laws",
    "security and financial indebtedness",
    "ranking of [Permitted Account security] /[Transaction Security]",
    "[good title to assets;]",
    "legal and beneficial ownership of the [Permitted Accounts] /[Charged Property]",
    "[shares;]",
    "[intellectual property;]",
    "Group Structure Chart",
    "pari passu ranking",
    "[environmental compliance and no environmental claims;]",
    "taxation compliance and no tax claims",
    "sanctions; anti-bribery and corruption",
    "centre of main interests and establishments",
    "[public procurement]",
    "[no immunity from suit, execution, attachment or other legal process;]",
    "[private and commercial acts;]",
    "no adverse consequences"
];
  
export const genovertakingOptions = [
  "authorisations",
  "compliance with laws",
  "environmental compliance",
  "environmental claims",
  "taxation",
  "sanctions",
  "anti-bribery and corruption",
  "restriction on merger",
  "no change of business",
  "restriction on acquisitions",
  "restriction on joint ventures",
  "holding companies",
  "preservation of assets",
  "pari passu ranking",
  "negative pledge",
  "restriction on disposals",
  "arm's length basis",
  "restriction on loans or credit",
  "restriction on guarantees or indemnities",
  "restriction on dividends and share redemption"
];

export const eventsOfDefaultOptions = [
  "any financial covenant not satisfied",
  "failure to comply with Permitted Account Undertakings",
  "misrepresentation",
  "cross default, subject to an agreed minimum amount",
  "insolvency",
  "insolvency proceedings",
  "creditors' process",
  "failure to comply with court judgment or arbitral award",
  "ownership of Obligors",
  "audit qualification",
  "expropriation/governmental intervention",
  "repudiation and rescission of agreements",
  "litigation",
  "cessation of business",
  "unlawfulness and invalidity",
  "convertibility / transferability",
  "moratorium",
  "political and economic risk",
  "material adverse change"
];
