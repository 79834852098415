export const CurrencyOptions = [
  {
    label: 'U.S. dollar (USD)',
    value: 'USD',
    prefix: '$',
  },
  {
    label: 'Euro (EUR)',
    value: 'EUR',
    prefix: '€',
  },
  {
    label: 'British pound sterling (GBP)',
    value: 'GBP',
    prefix: '£',
  },
  {
    label: 'Chinese Yuan',
    value: 'CNY',
    prefix: '¥',
  },
  {
    label: 'Japanese yen (JPY)',
    value: 'JPY',
    prefix: '¥',
  },
  {
    label: 'Swiss franc (CHF)',
    value: 'CHF',
    prefix: 'CHf',
  },
  {
    label: 'Canadian dollar (CAD)',
    value: 'CAD',
    prefix: '$',
  },
  {
    label: 'Australian dollar (AUD)',
    value: 'AUD',
    prefix: '$',
  },
  {
    label: 'New Zealand dollar (NZD)',
    value: 'NZD',
    prefix: '$',
  },
];